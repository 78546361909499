const navData = [
    {
        text: "portfolio",
        id: "portfolio"
    },

    {
        text: "locations",
        id: "locations"
    },

    {
        text: "prices",
        id: "prices"
    },

    {
        text: "contacts",
        id: "contacts"
    },
];

export default navData;